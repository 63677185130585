import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from '../../../services/AxiosInstance';

//import PageTitle from "../../layouts/PageTitle";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import profileM from "../../../images/avatar/male.png";
import profileF from "../../../images/avatar/female.png";

import { Dropdown, Tab, Nav, Alert, Table, Col, Row, Card } from "react-bootstrap";


import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)



// import Ext from "../../layouts/Ext";

const ApexLine4 = loadable(() =>
    pMinDelay(import("../charts/apexcharts/Line4"), 500)
);
const UserGraphChart = loadable(() =>
    pMinDelay(import("../charts/apexcharts/UserGraphChart"), 500)
);
const LevelOne = loadable(() =>
    pMinDelay(import("../charts/apexcharts/LevelOne"), 500)
);
const LevelTwo = loadable(() =>
    pMinDelay(import("../charts/apexcharts/LevelTwo"), 500)
);
const LevelThree = loadable(() =>
    pMinDelay(import("../charts/apexcharts/LevelThree"), 500)
);
const ApexNagetivePosative = loadable(() =>
    pMinDelay(import("../charts/apexcharts/NagetivePositive2"), 500)
);
const ApexRedialBar = loadable(() =>
    pMinDelay(import("../charts/apexcharts/RadialBar"), 500)
);
const Commitment = loadable(() =>
    pMinDelay(import("../charts/apexcharts/Commitment"), 500)
);

const Coach = (props) => {
    const [userId, setUserId] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');

    const [logs, setLogs] = useState([]);

    const SIX_MONTHS_IN_MILLISECONDS = 6 * 30 * 24 * 60 * 60 * 1000; // 6 months in milliseconds

    const calculateAge = () => {
        const currentDate = new Date();
        const selectedDate = new Date(userDetails.birthDate);
        const ageInMillis = currentDate - selectedDate;

        const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Considering leap years
        const ageInYears = Math.floor(ageInMillis / millisecondsInYear);

        setAge(ageInYears);
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
        const config = {
            headers: {
                authorization: "Bearer " + token
            }
        };

        // get the userId from the URL parameter
        const { id } = props.match.params;
        setUserId(id);

        const fetchUserDetails = async () => {
            try {
                const response = await axiosInstance.get(`/user/${id}`, config);

                setUserDetails(response.data);
                setGender(response.data.gender);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchLogs = async () => {
            try {
                const response = await axiosInstance.get(`/log/${id}`, config);
                const formattedLogs = response.data.map((log) => ({
                    ...log,
                    date: dayjs(log.time).format('MMMM D, YYYY, h:mm:ss A ') + ' (' + dayjs(log.time).fromNow() + ')'
                }));
                setLogs(formattedLogs);
            } catch (error) {
                console.log(error);
            }
        };

        fetchUserDetails();
        fetchLogs();
    }, []);

    useEffect(() => {
        if (userDetails.birthDate) {
            calculateAge();
        }
    }, [userDetails.birthDate]);




    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
                <i className="fa fa-caret-right" />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div
                className="owl-prev disabled"
                onClick={onClick}
                style={{ zIndex: 99 }}
            >
                <i className="fa fa-caret-left" />
            </div>
        );
    }

    const settings = {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            {/* <PageTitle activeMenu="Customers" motherMenu="Shop" /> */}

            <div className="row">
                <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                            <div className="photo-content">
                                <div className="cover-photo"></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                    <img
                                        src={(gender.toLowerCase() === 'female') ? profileF : profileM}
                                        //src={profileF}
                                        className="img-fluid rounded-circle"
                                        alt="profile"
                                    />
                                </div>
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2">
                                        <h4 className="text-primary mb-0">
                                            {userDetails.firstName} {" "} {userDetails.lastName}
                                        </h4>
                                        <p>{userDetails.role}</p>
                                    </div>
                                    <div className="profile-email px-2 pt-2">
                                        <h4 className="text-muted mb-0">
                                            {userDetails.email}
                                        </h4>
                                        <p>Age {age}</p>
                                    </div>
                                    <div className="profile-email px-2 pt-2">
                                        <h4 className="text-muted mb-0">
                                            Tel {userDetails.tel}
                                        </h4>
                                        <p>{gender}</p>
                                    </div>
                                    {/* <Button variant="primary" className="ml-auto">
                                        <Link
                                            to="/records">View All Records</Link>
                                    </Button> */}
                                    {/* <Link
                                        to="/diet-food-menu"
                                        className="btn btn-primary rounded  d-none d-lg-block ml-auto"
                                    >
                                        View All Records
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="row">

                <div className='col-xl-9 col-xxl-9 col-lg-9'>
                    <div className='card'>
                        <div className='card-header border-0 pb-0'>
                            <h4 className='card-title'>Recent Activities</h4>
                        </div>
                        <div className='card-body'>
                            <PerfectScrollbar
                                style={{ height: '370px' }}
                                id='DZ_W_TimeLine1'
                                className='widget-timeline dz-scroll style-1 height370 ps ps--active-y'
                            >
                                <ul className='timeline'>
                                    {logs.map((log) => (
                                        <li key={log.id}>
                                            <div className='timeline-badge primary'></div>
                                            <div
                                                className='timeline-panel text-muted'

                                            >
                                                <span>{log.date}</span>
                                                <h6 className='mb-0'>
                                                    {log.action}{' '}
                                                    <Link
                                                        to={`/member/${log.target._id}`}
                                                    >
                                                        <strong className='text-primary'>{log.target.firstName}{' '}{log.target.lastName}</strong>.
                                                    </Link>
                                                </h6>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>


                <div className="col-xl-3 col-xxl-4 col-md-6">
                    <div className="card">
                        <div className="card-header border-0 pb-0">
                            <h4 className="text-black fs-20 mb-0">Commitment</h4>
                        </div>
                        <div className="card-body text-center">

                            <Commitment commitmentVal={userDetails.commitment} />
                            <p className="fs-14">
                                The commitment level to the goal from 1 to 10
                            </p>
                            {/* <Link
                                to="/workout-statistic"
                                className="btn btn-outline-primary rounded"
                            >
                                Set Target
                            </Link> */}
                        </div>
                    </div>
                </div>


                <div className="col-xl-12">
                    <div className="card">
                        <Tab.Container defaultActiveKey="level1">
                            <div className="card-header d-sm-flex d-block pb-0 border-0">
                                <div className="mr-auto pr-3">
                                    <h4 className="text-black fs-20">
                                        Fitness Tests

                                    </h4>
                                    <p className="fs-13 mb-0 text-black">
                                        This graph represents the records of the last tests for each level.                                    </p>
                                </div>
                                <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                                    <Nav as="ul" className="nav nav-tabs" role="tablist">
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                data-toggle="tab"
                                                eventKey="level1"
                                            >
                                                Level 1
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link eventKey="level2">Level 2</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link eventKey="level3">Level 3</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                {/* <Link
                                    to="/diet-food-menu"
                                    className="btn btn-primary rounded d-none d-lg-block ml-0 ml-md-5"
                                >
                                    View More
                                </Link> */}
                            </div>
                            <div className="card-body fito-home-slider">
                                <Tab.Content className="tab-content">
                                    <Tab.Pane role="tabpanel" eventKey="level1">
                                        <div>

                                            <LevelOne {...props} />
                                            <Link
                                                to={`/LevelOne/${userId}`}
                                                className="btn btn-primary rounded d-lg-block ml-0 ml-md-5"
                                            >
                                                View All Level One Tests
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        // className={`tab-pane fade ${
                                        //    activeToggle === "level2" ? "active show" : ""
                                        // }`}
                                        id="Level 2"
                                        role="tabpanel"
                                        eventKey="level2"
                                    >
                                        <div>

                                            <LevelTwo {...props} />
                                            <Link
                                                to={`/LevelTwo/${userId}`}
                                                className="btn btn-primary rounded d-lg-block ml-0 ml-md-5"
                                            >
                                                View All Level Two Tests
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        // className={`tab-pane fade ${
                                        //    activeToggle === "level3" ? "active show" : ""
                                        // }`}
                                        id="Level 3"
                                        role="tabpanel"
                                        eventKey="level3"
                                    >
                                        <div>

                                            <LevelThree {...props} />
                                            <Link
                                                to={`/LevelThree/${userId}`}
                                                className="btn btn-primary rounded d-lg-block ml-0 ml-md-5"
                                            >
                                                View All Level Three Tests
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>

            </div>

        </>
    );
};

export default Coach;
