import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from '../../../services/AxiosInstance';
//import PageTitle from "../../layouts/PageTitle";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";
import swal from "sweetalert";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import profileM from "../../../images/avatar/male.png";
import profileF from "../../../images/avatar/female.png";

import { Dropdown, Tab, Nav, Alert, Button, Modal, Form } from "react-bootstrap";


// import Ext from "../../layouts/Ext";

const ApexLine4 = loadable(() =>
    pMinDelay(import("../charts/apexcharts/Line4"), 500)
);
const UserGraphChart = loadable(() =>
    pMinDelay(import("../charts/apexcharts/UserGraphChart"), 500)
);
const LevelOne = loadable(() =>
    pMinDelay(import("../charts/apexcharts/LevelOne"), 500)
);
const LevelTwo = loadable(() =>
    pMinDelay(import("../charts/apexcharts/LevelTwo"), 500)
);
const LevelThree = loadable(() =>
    pMinDelay(import("../charts/apexcharts/LevelThree"), 500)
);
const ApexNagetivePosative = loadable(() =>
    pMinDelay(import("../charts/apexcharts/NagetivePositive2"), 500)
);
const ApexRedialBar = loadable(() =>
    pMinDelay(import("../charts/apexcharts/RadialBar"), 500)
);
const Commitment = loadable(() =>
    pMinDelay(import("../charts/apexcharts/Commitment"), 500)
);

const Member = (props) => {

    const [userId, setUserId] = useState('');
    const [measurement, setMeasurement] = useState('null');
    //const [commitment, setCommitment] = useState('');
    //const [fullName, setFullName] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');

    const [childBirth, setChildBirth] = useState('');
    const [childBirthDate, setChildBirthDate] = useState('');
    const [childBirthMethod, setChildBirthMethod] = useState('');
    //const [occupation, setOccupation] = useState('');
    const [userDetails, setUserDetails] = useState('');

    const [dailyMeals, setDailyMeals] = useState(([]));
    const menus = [menu01, menu02, menu03];
    const randomMenu = menus[Math.floor(Math.random() * menus.length)];


    const SIX_MONTHS_IN_MILLISECONDS = 6 * 30 * 24 * 60 * 60 * 1000; // 6 months in milliseconds

    const calculateAge = () => {
        const currentDate = new Date();
        const selectedDate = new Date(userDetails.birthDate);
        const ageInMillis = currentDate - selectedDate;

        const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Considering leap years
        const ageInYears = Math.floor(ageInMillis / millisecondsInYear);

        setAge(ageInYears);
    };



    const now = new Date();
    const sixMonthsAgo = new Date(now.getTime() - SIX_MONTHS_IN_MILLISECONDS);


    const [showModal, setShowModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

    const handleShowProfileModal = () => {
        setShowProfileModal(true);

        // Set the current values as the default values in the profile modal
        setProfileFormData((prevProfileFormData) => ({
            ...prevProfileFormData,
            firstName: userDetails?.firstName || '',
            lastName: userDetails?.lastName || '',
            occupation: userDetails?.occupation || '',
            birthDate: userDetails?.birthDate ? new Date(userDetails.birthDate).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10),
            tel: userDetails?.tel || '',
            gender: userDetails?.gender || ''
        }));
    };

    const handleShowMoreInfoModal = () => {
        setShowMoreInfoModal(true);

        // Set the current values as the default values in the moreInfo modal
        setMoreInfoFormData((prevMoreInfoFormData) => ({
            ...prevMoreInfoFormData,
            active: userDetails?.active !== undefined ? userDetails.active.toString() : 'false',
            otherActivity: userDetails?.otherActivity || '',
            weightTraining: userDetails?.weightTraining !== undefined ? userDetails.weightTraining.toString() : 'false',
            fitnessGoal: userDetails?.fitnessGoal || '',
            trainingFrequency: userDetails?.trainingFrequency || '',
        }));
    };

    const handleShowModal = () => {
        setShowModal(true);

        // Set the current values as the default values in the modal
        setFormData((prevFormData) => ({
            ...prevFormData,
            backPain: userDetails?.chronicCondition?.backPain || false,
            cholesterol: userDetails?.chronicCondition?.cholesterol || false,
            diabetes: userDetails?.chronicCondition?.diabetes || false,
            arthritis: userDetails?.chronicCondition?.arthritis || false,
            bloodPressure: userDetails?.chronicCondition?.bloodPressure || false,
            surgery: userDetails?.chronicCondition?.surgery || '',
            other: userDetails?.chronicCondition?.other || ''
        }));
    };


    const handleCloseProfileModal = () => {
        setShowProfileModal(false);
    };

    const handleCloseMoreInfoModal = () => {
        setShowMoreInfoModal(false);
    };


    const handleCloseModal = () => {
        setShowModal(false);
    };


    const [profileFormData, setProfileFormData] = useState({
        firstName: '',
        lastName: '',
        occupation: '',
        birthDate: '',
        tel: '',
        gender: ''
    });

    const [moreInfoFormData, setMoreInfoFormData] = useState({
        active: '',
        otherActivity: '',
        weightTraining: '',
        fitnessGoal: '',
        trainingFrequency: '',
    });

    const [formData, setFormData] = useState({
        backPain: false,
        cholesterol: false,
        diabetes: false,
        arthritis: false,
        bloodPressure: false,
        surgery: '',
        other: ''
    });

    const handleProfileInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setProfileFormData((prevFormData) => ({
            ...prevFormData,
            [name]: fieldValue
        }));
    };

    const handleMoreInfoInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setMoreInfoFormData((prevFormData) => ({
            ...prevFormData,
            [name]: fieldValue
        }));
    };


    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: fieldValue
        }));
    };

    const handleProfileSubmit = async (event) => {
        event.preventDefault(); // prevent the default form submit behavior

        const { id } = props.match.params;

        // Prepare the profile data to be sent to the server
        const data = {
            userID: id,
            firstName: profileFormData.firstName,
            lastName: profileFormData.lastName,
            occupation: profileFormData.occupation,
            birthDate: profileFormData.birthDate,
            tel: profileFormData.tel,
            gender: profileFormData.gender,
        };

        try {
            // Send a PUT request to the server to update the profile
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token,
                },
            };
            await axiosInstance.put(`/user/updateProfile/${id}`, data, config);

            // Clear the form data and close the modal
            setProfileFormData({
                firstName: "",
                lastName: "",
                occupation: "",
                birthDate: "",
                tel: "",
                gender: "",
            });
            setShowProfileModal(false);

            // Show a success swal
            swal("Good job!", "Profile Updated", "success");

            // Fetch the updated user details and update the state
            const updatedUserDetails = await axiosInstance.get(`/user/${id}`, config);
            setUserDetails(updatedUserDetails.data);
            setGender(updatedUserDetails.data.gender)
        } catch (error) {
            console.error(error);

            // Show a fail swal
            swal({
                title: "Oops...",
                text: "Failed to update the profile. Please try again!",
                icon: "error",
                buttons: {
                    confirm: "Try Again",
                },
            });
        }
    };


    const handleMoreInfoSubmit = async (event) => {
        event.preventDefault(); // prevent the default form submit behavior

        const { id } = props.match.params;

        // Prepare the moreInfo data to be sent to the server
        const data = {
            userID: id,
            active: moreInfoFormData.active === 'true',
            otherActivity: moreInfoFormData.otherActivity,
            weightTraining: moreInfoFormData.weightTraining === 'true',
            fitnessGoal: moreInfoFormData.fitnessGoal,
            trainingFrequency: moreInfoFormData.trainingFrequency,
        };

        try {
            // Send a PUT request to the server to update the moreInfo section
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token,
                },
            };
            await axiosInstance.put(`/user/updateMoreInfo/${id}`, data, config);

            // Clear the form data and close the modal
            setMoreInfoFormData({
                active: "",
                otherActivity: "",
                weightTraining: "",
                fitnessGoal: "",
                trainingFrequency: ""
            });
            setShowMoreInfoModal(false);

            // Show a success swal
            swal("Good job!", "More Info Updated", "success");

            // Fetch the updated user details and update the state
            const updatedUserDetails = await axiosInstance.get(`/user/${id}`, config);
            setUserDetails(updatedUserDetails.data);
        } catch (error) {
            console.error(error);

            // Show a fail swal
            swal({
                title: "Oops...",
                text: "Failed to update the more info. Please try again!",
                icon: "error",
                buttons: {
                    confirm: "Try Again",
                },
            });
        }
    };



    const handleSubmit = async (event) => {
        event.preventDefault(); // prevent the default form submit behavior

        const { id } = props.match.params;

        // Prepare the chronic condition data to be sent to the server
        const data = {
            userID: id,
            backPain: formData.backPain,
            cholesterol: formData.cholesterol,
            diabetes: formData.diabetes,
            arthritis: formData.arthritis,
            bloodPressure: formData.bloodPressure,
            surgery: formData.surgery,
            other: formData.other
        };

        try {
            // Send a post request to the server to save the chronic condition
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token
                }
            };
            await axiosInstance.put(`/user/updateChronicCondition/${id}`, data, config);

            // Clear the form data and close the modal
            setFormData({
                backPain: false,
                cholesterol: false,
                diabetes: false,
                arthritis: false,
                bloodPressure: false,
                surgery: '',
                other: ''
            });
            setShowModal(false);

            // Show a success swal
            swal('Good job!', 'Chronic Condition Updated', 'success');

            // Fetch the updated user details and update the state
            const updatedUserDetails = await axiosInstance.get(`/user/${id}`, config);
            setUserDetails(updatedUserDetails.data);

        } catch (error) {
            console.error(error);

            // Show a fail swal
            swal({
                title: 'Oops...',
                text: 'Failed to update the chronic condition. Please try again!',
                icon: 'error',
                buttons: {
                    confirm: "Try Again"
                }
            });
        }
    };


    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
        const config = {
            headers: {
                authorization: "Bearer " + token
            }
        };

        // get the userId from the URL parameter
        const { id } = props.match.params;
        setUserId(id);

        // fetch the latest measurement data for the user
        const fetchMeasurement = async () => {
            try {
                const response = await axiosInstance.get(`/measurement/latest/${id}`, config);
                setMeasurement(response.data);

            } catch (error) {
                console.log(error);
            }
        };
        fetchMeasurement();

        const fetchUserDetails = async () => {
            try {
                const response = await axiosInstance.get(`/user/${id}`, config);
                setUserDetails(response.data);
                setGender(response.data.gender)
                setChildBirth(response.data.childBirth.isTrue)
                setChildBirthDate(response.data.childBirth.date)
                setChildBirthMethod(response.data.childBirth.method)


            } catch (error) {
                console.log(error);
            }
        };
        fetchUserDetails();

        const fetchDailyMeals = async () => {
            try {
                const response = await axiosInstance.get(`/dailyMeal/dailyMeals/${id}`, config);
                setDailyMeals(response.data);

            } catch (error) {
                console.log(error);
            }
        };
        fetchDailyMeals();
    }, []);

    useEffect(() => {
        if (userDetails.birthDate) {
            calculateAge();
        }
    }, [userDetails.birthDate]);


    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
                <i className="fa fa-caret-right" />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div
                className="owl-prev disabled"
                onClick={onClick}
                style={{ zIndex: 99 }}
            >
                <i className="fa fa-caret-left" />
            </div>
        );
    }

    const settings = {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            {/* <PageTitle activeMenu="Customers" motherMenu="Shop" /> */}




            <Modal show={showProfileModal} onHide={handleCloseProfileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Member Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name:</label>
                            <input
                                type="text"
                                name="firstName"
                                className="form-control"
                                value={profileFormData.firstName}
                                onChange={handleProfileInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name:</label>
                            <input
                                type="text"
                                name="lastName"
                                className="form-control"
                                value={profileFormData.lastName}
                                onChange={handleProfileInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="occupation">Occupation:</label>
                            <input
                                type="text"
                                name="occupation"
                                className="form-control"
                                value={profileFormData.occupation}
                                onChange={handleProfileInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="birthDate">Birthdate:</label>
                            <input
                                type="date"
                                name="birthDate"
                                className="form-control"
                                value={profileFormData.birthDate || ''}
                                onChange={handleProfileInputChange}
                            />

                        </div>
                        <div className="form-group">
                            <label htmlFor="tel">Phone Number:</label>
                            <input
                                type="text"
                                name="tel"
                                className="form-control"
                                value={profileFormData.tel}
                                onChange={handleProfileInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Gender:</label>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value="Male"
                                    checked={profileFormData.gender === "Male"}
                                    onChange={handleProfileInputChange}
                                    className="form-check-input"
                                />
                                <label htmlFor="male" className="form-check-label">Male</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="Female"
                                    checked={profileFormData.gender === "Female"}
                                    onChange={handleProfileInputChange}
                                    className="form-check-input"
                                />
                                <label htmlFor="female" className="form-check-label">Female</label>
                            </div>
                        </div>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleProfileSubmit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showMoreInfoModal} onHide={handleCloseMoreInfoModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update More Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="form-group">
                            <label htmlFor="active">Worked out before:</label>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    id="active-yes"
                                    name="active"
                                    value="true"
                                    checked={moreInfoFormData.active === 'true'}
                                    onChange={handleMoreInfoInputChange}
                                    className="form-check-input"
                                />
                                <label htmlFor="active-yes" className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    id="active-no"
                                    name="active"
                                    value="false"
                                    checked={moreInfoFormData.active === 'false'}
                                    onChange={handleMoreInfoInputChange}
                                    className="form-check-input"
                                />
                                <label htmlFor="active-no" className="form-check-label">No</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="otherActivity">Other Activity:</label>
                            <input
                                type="text"
                                name="otherActivity"
                                className="form-control"
                                value={moreInfoFormData.otherActivity}
                                onChange={handleMoreInfoInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="weightTraining">Currently participating in weight training:</label>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    id="weightTraining-yes"
                                    name="weightTraining"
                                    value="true"
                                    checked={moreInfoFormData.weightTraining === 'true'}
                                    onChange={handleMoreInfoInputChange}
                                    className="form-check-input"
                                />
                                <label htmlFor="weightTraining-yes" className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    id="weightTraining-no"
                                    name="weightTraining"
                                    value="false"
                                    checked={moreInfoFormData.weightTraining === 'false'}
                                    onChange={handleMoreInfoInputChange}
                                    className="form-check-input"
                                />
                                <label htmlFor="weightTraining-no" className="form-check-label">No</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="fitnessGoal">Fitness Goal:</label>
                            <input
                                type="text"
                                name="fitnessGoal"
                                className="form-control"
                                value={moreInfoFormData.fitnessGoal}
                                onChange={handleMoreInfoInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="trainingFrequency">Training Frequency:</label>
                            <input
                                type="number"
                                name="trainingFrequency"
                                className="form-control"
                                value={moreInfoFormData.trainingFrequency}
                                onChange={handleMoreInfoInputChange}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleMoreInfoSubmit}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Chronic Condition</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="form-group">
                            <label htmlFor="backPain">Back Pain:</label>
                            <input
                                className="ml-3"
                                type="checkbox"
                                name="backPain"
                                checked={formData.backPain}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="cholesterol">Cholesterol:</label>
                            <input
                                className="ml-3"
                                type="checkbox"
                                name="cholesterol"
                                checked={formData.cholesterol}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="diabetes">Diabetes:</label>
                            <input
                                className="ml-3"
                                type="checkbox"
                                name="diabetes"
                                checked={formData.diabetes}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="arthritis">Arthritis:</label>
                            <input
                                className="ml-3"
                                type="checkbox"
                                name="arthritis"
                                checked={formData.arthritis}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="bloodPressure">Blood Pressure:</label>
                            <input
                                className="ml-3"
                                type="checkbox"
                                name="bloodPressure"
                                checked={formData.bloodPressure}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="surgery">Surgery:</label>
                            <input
                                type="text"
                                name="surgery"
                                className="form-control"
                                value={formData.surgery}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="other">Other:</label>
                            <input
                                type="text"
                                name="other"
                                className="form-control"
                                value={formData.other}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Update Chronic Condition
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="row">
                <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                            <div className="photo-content">
                                <div className="cover-photo"></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                    <img
                                        src={(gender.toLowerCase() === 'female') ? profileF : profileM}
                                        //src={profileF}
                                        className="img-fluid rounded-circle"
                                        alt="profile"
                                    />
                                </div>
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2">
                                        <h4 className="text-primary mb-0">
                                            {userDetails.firstName} {""} {userDetails.lastName}
                                        </h4>
                                        <p>{userDetails.occupation}</p>
                                    </div>
                                    <div className="profile-email px-2 pt-2">
                                        <h4 className="text-muted mb-0">
                                            {userDetails.email}
                                        </h4>
                                        <p>Age {age}</p>
                                    </div>
                                    <div className="profile-email px-2 pt-2">
                                        <h4 className="text-muted mb-0">
                                            Tel {userDetails.tel}
                                        </h4>
                                        <p>{gender}</p>
                                    </div>
                                    {/* <Button variant="primary" className="ml-auto">
                                        <Link
                                            to="/records">View All Records</Link>
                                    </Button> */}
                                    <Button className="btn bg-white text-primary font-w600 mt-sm-0 mt-3 mb-0 ml-auto" onClick={handleShowProfileModal}>Edit <i className="fa fa-pencil"></i></Button>
                                    {/* <Link
                                        to="/diet-food-menu"
                                        className="btn btn-primary rounded  d-none d-lg-block ml-auto"
                                    >
                                        View All Records
                                    </Link> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {gender.toLowerCase() === 'female' && childBirth == true && new Date(childBirthDate) > sixMonthsAgo ? (
                <div className="row">
                    <div className='col-xl-12 col-lg-12 col-sm-12'>
                        <Alert
                            variant="success"
                            className="alert-dismissible fade show"
                        >
                            <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="mr-2"
                            >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                            </svg>
                            Mrs.<strong> {userDetails.firstName} {""} {userDetails.lastName}</strong> gave birth in the last 6 months: <strong>{childBirthMethod}</strong>
                        </Alert>
                    </div>
                </div>
            ) : null}



            <div className="row">

                <div className='col-xl-6 col-lg-12 col-sm-12'>
                    <div className='card'>
                        <div className='card-header border-0 pb-0'>
                            <h2 className='card-title'><strong>More Informations</strong></h2>
                            <Button className="btn bg-white text-primary font-w600 mt-sm-0 mt-3 mb-0 ml-auto" onClick={handleShowMoreInfoModal}>Edit <i className="fa fa-pencil"></i></Button>

                        </div>
                        <div className='card-body pb-0'>
                            <div>
                                <Link
                                    to={`/records/${userId}`}
                                    className="btn btn-primary mb-1 mr-1"
                                >
                                    View All Measurements
                                </Link>
                                <Link
                                    to={`/sessions-history/${userId}`}
                                    className="btn btn-primary mb-1 mr-1"
                                >
                                    View All Sessions History
                                </Link>

                            </div>
                            <ul className='list-group list-group-flush'>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Worked out before</span>
                                    <strong className='mb-0'>{userDetails.active !== undefined ? (userDetails.active ? 'Yes' : 'No') : 'N/A'}</strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Other Activity</span>
                                    <strong className='mb-0'>{userDetails.otherActivity !== undefined ? userDetails.otherActivity : 'N/A'}</strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Currently participating in weight training</span>
                                    <strong className='mb-0'>{userDetails.weightTraining !== undefined ? (userDetails.weightTraining ? 'Yes' : 'No') : 'N/A'}</strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Fitness Goal</span>
                                    <strong className='mb-0'>{userDetails.fitnessGoal !== undefined ? userDetails.fitnessGoal : 'N/A'}</strong>
                                </li>
                            </ul>
                        </div>
                        <div className='card-footer pt-0 pb-0 text-center'>
                            <div className='row'>
                                <div className='col-4 pt-3 pb-3 border-right'>
                                    <h3 className='mb-1 text-primary'>{measurement ? measurement.weight : 'N/A'}kg</h3>
                                    <span>Current Weight</span>
                                </div>
                                <div className='col-4 pt-3 pb-3 border-right'>
                                    <h3 className='mb-1 text-primary'>{measurement ? measurement.height : 'N/A'}cm</h3>
                                    <span>Current Height</span>
                                </div>
                                <div className='col-4 pt-3 pb-3'>
                                    <h3 className='mb-1 text-primary'>{userDetails.trainingFrequency !== undefined ? userDetails.trainingFrequency : 'N/A'}/week</h3>
                                    <span>Training Frequency</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-xl-6 col-lg-6 col-xxl-4 col-sm-6'>
                    <div className='card text-white bg-primary'>
                        <div className='card-header border-0 pb-0'>
                            <h2 className='card-title text-white'><strong>Chronic Condition</strong></h2>
                            <Button className="btn bg-white text-primary font-w600 mt-sm-0 mt-3 mb-0" onClick={handleShowModal}>Edit <i className="fa fa-pencil"></i></Button>
                        </div>
                        <div className='card-body pb-0'>

                            <ul className='list-group list-group-flush'>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Back Pain</span>
                                    <strong className='mb-0'>
                                        {userDetails?.chronicCondition?.backPain !== undefined
                                            ? userDetails.chronicCondition.backPain
                                                ? 'Yes'
                                                : 'No'
                                            : 'N/A'}
                                    </strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Cholesterol</span>
                                    <strong className='mb-0'>
                                        {userDetails?.chronicCondition?.cholesterol !== undefined
                                            ? userDetails.chronicCondition.cholesterol
                                                ? 'Yes'
                                                : 'No'
                                            : 'N/A'}
                                    </strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Diabetes</span>
                                    <strong className='mb-0'>
                                        {userDetails?.chronicCondition?.diabetes !== undefined
                                            ? userDetails.chronicCondition.diabetes
                                                ? 'Yes'
                                                : 'No'
                                            : 'N/A'}
                                    </strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Arthritis</span>
                                    <strong className='mb-0'>
                                        {userDetails?.chronicCondition?.arthritis !== undefined
                                            ? userDetails.chronicCondition.arthritis
                                                ? 'Yes'
                                                : 'No'
                                            : 'N/A'}
                                    </strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Blood Pressure</span>
                                    <strong className='mb-0'>
                                        {userDetails?.chronicCondition?.bloodPressure !== undefined
                                            ? userDetails.chronicCondition.bloodPressure
                                                ? 'Yes'
                                                : 'No'
                                            : 'N/A'}
                                    </strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Surgery</span>
                                    <strong className='mb-0'>
                                        {userDetails?.chronicCondition?.surgery && userDetails.chronicCondition.surgery !== ''
                                            ? userDetails.chronicCondition.surgery
                                            : 'No'}
                                    </strong>
                                </li>
                                <li className='list-group-item d-flex px-0 justify-content-between'>
                                    <span>Other</span>
                                    <strong className='mb-0'>
                                        {userDetails?.chronicCondition?.other && userDetails.chronicCondition.other !== ''
                                            ? userDetails.chronicCondition.other
                                            : 'No'}
                                    </strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>



                <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
                    <div className='widget-stat card bg-danger'>
                        <div className='card-body  p-4'>
                            <div className='media'>
                                <span className='mr-3'>
                                    <i className='flaticon-381-equal'></i>
                                </span>
                                <div className='media-body text-white text-right'>
                                    <p className='mb-1'>Arms</p>
                                    <h3 className='text-white'>{measurement ? measurement.arms : 'N/A'}cm</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
                    <div className='widget-stat card bg-success'>
                        <div className='card-body p-4'>
                            <div className='media'>
                                <span className='mr-3'>
                                    <i className='flaticon-381-speedometer'></i>
                                </span>
                                <div className='media-body text-white text-right'>
                                    <p className='mb-1'>Chest</p>
                                    <h3 className='text-white'>{measurement ? measurement.chest : 'N/A'}cm</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
                    <div className='widget-stat card bg-info'>
                        <div className='card-body p-4'>
                            <div className='media'>
                                <span className='mr-3'>
                                    <i className='flaticon-381-heart'></i>
                                </span>
                                <div className='media-body text-white text-right'>
                                    <p className='mb-1'>Waist</p>
                                    <h3 className='text-white'>{measurement ? measurement.waist : 'N/A'}cm</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
                    <div className='widget-stat card bg-primary'>
                        <div className='card-body p-4'>
                            <div className='media'>
                                <span className='mr-3'>
                                    <i className='flaticon-381-note'></i>
                                </span>
                                <div className='media-body text-white text-right'>
                                    <p className='mb-1'>Belly</p>
                                    <h3 className='text-white'>{measurement ? measurement.belly : 'N/A'}cm</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
                    <div className='widget-stat card bg-danger'>
                        <div className='card-body  p-4'>
                            <div className='media'>
                                <span className='mr-3'>
                                    <i className='flaticon-381-equal'></i>
                                </span>
                                <div className='media-body text-white text-right'>
                                    <p className='mb-1'>Hips</p>
                                    <h3 className='text-white'>{measurement ? measurement.hips : 'N/A'}cm</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
                    <div className='widget-stat card bg-success'>
                        <div className='card-body p-4'>
                            <div className='media'>
                                <span className='mr-3'>
                                    <i className='flaticon-381-speedometer'></i>
                                </span>
                                <div className='media-body text-white text-right'>
                                    <p className='mb-1'>Legs</p>
                                    <h3 className='text-white'>{measurement ? measurement.legs : 'N/A'}cm</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-xl-9 col-xxl-8">
                    <div className="card">
                        <div className="card-header flex-wrap pb-0 border-0">
                            <div className="mr-auto pr-3 mb-2">
                                <h4 className="text-black fs-20">Weight Progress</h4>

                            </div>
                            <div className="d-flex mr-3 mr-sm-5 mb-2">
                                <div>
                                    <h4 className="fs-18 text-primary font-w600 mb-0">
                                        {measurement ? measurement.weight : 'N/A'}kg
                                    </h4>
                                    <span className="fs-12 text-black">Current Weight</span>
                                </div>
                            </div>
                            <div className="d-flex mr-3 mr-sm-5 mb-2">
                                <div>

                                    <h4 className="fs-18 text-info font-w600 mb-0">
                                        {/* <i
                                            className="fa fa-star-o mr-3 scale5 text-warning"
                                            aria-hidden="true"
                                        /> */}
                                        {measurement ? measurement.fat : 'N/A'}%
                                    </h4>
                                    <span className="fs-12 text-black">Current Body Fat</span>
                                </div>
                            </div>
                            <div className="d-flex mr-3 mr-sm-5 mb-2">
                                <div>
                                    <h4 className="fs-18 text-warning font-w600 mb-0">
                                        {measurement ? measurement.muscle : 'N/A'}%                                    </h4>
                                    <span className="fs-12 text-black">Current Muscle Mass</span>
                                </div>
                            </div>
                            <Dropdown className="dropdown mt-sm-0 mt-3 mb-0">
                                <Dropdown.Toggle
                                    variant=""
                                    as="button"
                                    className="btn rounded border border-light dropdown-toggle"
                                >
                                    Last 5 Records
                                </Dropdown.Toggle>
                                {/* <Dropdown.Menu className="dropdown-menu-right">
                                    <Dropdown.Item>Link 1</Dropdown.Item>
                                    <Dropdown.Item>Link 2</Dropdown.Item>
                                    <Dropdown.Item>Link 3</Dropdown.Item>
                                </Dropdown.Menu> */}
                            </Dropdown>
                        </div>
                        <div className="card-body pt-3">
                            {/* <ApexLine4 /> */}
                            <UserGraphChart {...props} />
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-xxl-4 col-md-6">
                    <div className="card">
                        <div className="card-header border-0 pb-0">
                            <h4 className="text-black fs-20 mb-0">Commitment</h4>
                        </div>
                        <div className="card-body text-center">

                            <Commitment commitmentVal={userDetails.commitment} />
                            <p className="fs-14">
                                The commitment level to the goal from 1 to 10
                            </p>
                            {/* <Link
                                to="/workout-statistic"
                                className="btn btn-outline-primary rounded"
                            >
                                Set Target
                            </Link> */}
                        </div>
                    </div>
                </div>


                <div className="col-xl-12">
                    <div className="card">
                        <Tab.Container defaultActiveKey="level1">
                            <div className="card-header d-sm-flex d-block pb-0 border-0">
                                <div className="mr-auto pr-3">
                                    <h4 className="text-black fs-20">
                                        Fitness Tests

                                    </h4>
                                    <p className="fs-13 mb-0 text-black">
                                        This graph represents the records of the last tests for each level.                                    </p>
                                </div>
                                <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                                    <Nav as="ul" className="nav nav-tabs" role="tablist">
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                data-toggle="tab"
                                                eventKey="level1"
                                            >
                                                Level 1
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link eventKey="level2">Level 2</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link eventKey="level3">Level 3</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                {/* <Link
                                    to="/diet-food-menu"
                                    className="btn btn-primary rounded d-none d-lg-block ml-0 ml-md-5"
                                >
                                    View More
                                </Link> */}
                            </div>
                            <div className="card-body fito-home-slider">
                                <Tab.Content className="tab-content">
                                    <Tab.Pane role="tabpanel" eventKey="level1">
                                        <div>

                                            <LevelOne {...props} />
                                            <Link
                                                to={`/LevelOne/${userId}`}
                                                className="btn btn-primary rounded  d-lg-block ml-0 ml-md-5"
                                            >
                                                View All Level One Tests
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        // className={`tab-pane fade ${
                                        //    activeToggle === "level2" ? "active show" : ""
                                        // }`}
                                        id="Level 2"
                                        role="tabpanel"
                                        eventKey="level2"
                                    >
                                        <div>

                                            <LevelTwo {...props} />
                                            <Link
                                                to={`/LevelTwo/${userId}`}
                                                className="btn btn-primary rounded d-lg-block ml-0 ml-md-5"
                                            >
                                                View All Level Two Tests
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        // className={`tab-pane fade ${
                                        //    activeToggle === "level3" ? "active show" : ""
                                        // }`}
                                        id="Level 3"
                                        role="tabpanel"
                                        eventKey="level3"
                                    >
                                        <div>

                                            <LevelThree {...props} />
                                            <Link
                                                to={`/LevelThree/${userId}`}
                                                className="btn btn-primary rounded d-lg-block ml-0 ml-md-5"
                                            >
                                                View All Level Three Tests
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>



                <div className="col-xl-12">
                    <div className="card">

                        <div className="card-header d-sm-flex d-block pb-0 border-0">
                            <div className="mr-auto pr-3">
                                <h4 className="text-black fs-20">
                                    Your Daily Meal Example
                                </h4>
                                {/* <p className="fs-13 mb-0 text-black">
                                    Lorem ipsum dolor sit amet, consectetur
                                </p> */}
                            </div>

                            {/* <Link
                                to="/diet-food-menu"
                                className="btn btn-primary rounded d-none d-lg-block ml-0 ml-md-5"
                            >
                                View More
                            </Link> */}
                        </div>
                        <div className="card-body fito-home-slider">


                            <div className="featured-menus owl-carousel">
                                <Slider {...settings}>
                                    {dailyMeals.map((meal) => (
                                        <div key={meal.id} className="items">
                                            <div className="d-sm-flex p-3 border border-light rounded">
                                                <img
                                                    className="mr-4 food-image rounded"
                                                    src={randomMenu}
                                                    alt=""
                                                    width={160}
                                                />
                                                <div>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <span className="fs-14 text-primary">
                                                            {meal.mealType}
                                                        </span>
                                                    </div>
                                                    <h6 className="fs-16 mb-4">
                                                        {meal.description}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className="items">
                                        <div className="d-sm-flex p-3 border border-light rounded">
                                            <Link to="/ecom-product-detail">
                                                <img
                                                    className="mr-4 food-image rounded"
                                                    src={menu01}
                                                    alt=""
                                                    width={160}
                                                />
                                            </Link>
                                            <div>
                                                <div className="d-flex align-items-center mb-2">
                                                    <img
                                                        className="rounded-circle mr-2 profile-image"
                                                        src={testimonial}
                                                        alt=""
                                                        width={30}
                                                    />
                                                    <span className="fs-14 text-primary">
                                                        Andrew
                                                    </span>
                                                </div>
                                                <h6 className="fs-16 mb-4">
                                                    <Link
                                                        to="/ecom-product-detail"
                                                        className="text-black"
                                                    >
                                                        Fresh or Frozen (No Sugar
                                                        Added) Fruits
                                                    </Link>
                                                </h6>
                                                <ul>
                                                    <li className="mb-2">
                                                        <i className="las la-clock scale5 mr-3" />
                                                        <span className="fs-14 text-black">
                                                            4-6 mins{" "}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i
                                                            className="fa fa-star-o mr-3 scale5 text-warning"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="fs-14 text-black font-w500">
                                                            568 Reviews
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="items">
                                        <div className="d-sm-flex p-3 border border-light rounded">
                                            <Link to="/ecom-product-detail">
                                                <img
                                                    className="mr-4 food-image rounded"
                                                    src={menu01}
                                                    alt=""
                                                    width={160}
                                                />
                                            </Link>
                                            <div>
                                                <div className="d-flex align-items-center mb-2">
                                                    <img
                                                        className="rounded-circle mr-2 profile-image"
                                                        src={testimonial}
                                                        alt=""
                                                        width={30}
                                                    />
                                                    <span className="fs-14 text-primary">
                                                        Andrew
                                                    </span>
                                                </div>
                                                <h6 className="fs-16 mb-4">
                                                    <Link
                                                        to="/ecom-product-detail"
                                                        className="text-black"
                                                    >
                                                        Fresh or Frozen (No Sugar
                                                        Added) Fruits
                                                    </Link>
                                                </h6>
                                                <ul>
                                                    <li className="mb-2">
                                                        <i className="las la-clock scale5 mr-3" />
                                                        <span className="fs-14 text-black">
                                                            4-6 mins{" "}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i
                                                            className="fa fa-star-o mr-3 scale5 text-warning"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="fs-14 text-black font-w500">
                                                            568 Reviews
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="items">
                                        <div className="d-sm-flex p-3 border border-light rounded">
                                            <Link to="/ecom-product-detail">
                                                <img
                                                    className="mr-4 food-image rounded"
                                                    src={menu02}
                                                    alt=""
                                                    width={160}
                                                />
                                            </Link>
                                            <div>
                                                <div className="d-flex align-items-center mb-2">
                                                    <img
                                                        className="rounded-circle mr-2 profile-image"
                                                        src={testimonial2}
                                                        alt=""
                                                        width={30}
                                                    />
                                                    <span className="fs-14 text-primary">
                                                        Chintya
                                                    </span>
                                                </div>
                                                <h6 className="fs-16 mb-4">
                                                    <Link
                                                        to="/ecom-product-detail"
                                                        className="text-black"
                                                    >
                                                        Chicken Egg with fresh
                                                        tomatos
                                                    </Link>
                                                </h6>
                                                <ul>
                                                    <li className="mb-2">
                                                        <i className="las la-clock scale5 mr-3" />
                                                        <span className="fs-14 text-black">
                                                            4-6 mins{" "}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <i
                                                            className="fa fa-star-o mr-3 scale5 text-warning"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="fs-14 text-black font-w500">
                                                            223 Reviews
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                </Slider>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Member;
