import React, { Fragment, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Modal,
    Form,
    Badge,
    Dropdown,
    ProgressBar,
} from "react-bootstrap";


import swal from "sweetalert";
import axiosInstance from '../../../services/AxiosInstance';


/// imge
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import { Link } from "react-router-dom";

const Coaches = (props) => {
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );

    const [coaches, setCoaches] = React.useState([]);
    const [filteredCoaches, setFilteredCoaches] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");


    const [userRole, setUserRole] = useState("");



    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        tel: "",
        birthdate: "",
        gender: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // prevent the default form submit behavior

        // prepare the coach data to be sent to the server
        const data = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            tel: formData.tel,
            birthDate: formData.birthDate,
            gender: formData.gender,
        };

        try {
            // send a post request to the server to save the new coach
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token,
                },
            };
            await axiosInstance.post(`/user/createCoach`, data, config);

            // clear the form data and close the modal
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                tel: "",
                birthDate: "",
                gender: "",
            });
            setShowModal(false);

            // fetch the updated coaches and update the state
            const response = await axiosInstance.get(`/user/coaches`, config);
            setCoaches(response.data);
            setFilteredCoaches(response.data);

            // show a success swal
            swal('Good job!', 'Successfully Added', 'success');
        } catch (error) {
            console.error(error);

            // show a fail swal
            swal({
                title: 'Oops...',
                text: 'Please fill all the fields and try again!',
                icon: 'error',
                buttons: {
                    confirm: "Try Again"
                }
            });
        }
    };


    React.useEffect(() => {
        const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
        const config = {
            headers: {
                authorization: "Bearer " + token,
            },
        };

        const fetchUserDetails = async () => {
            try {
                const response = await axiosInstance.get(`/user/currentUser`, config);
                setUserRole(response.data.role);
                console.log(userRole);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchUsers = async () => {
            try {
                const response = await axiosInstance.get("/user/coaches", config);
                setCoaches(response.data);
                setFilteredCoaches(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchUserDetails();
        fetchUsers();
    }, []);




    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        const filteredCoaches = coaches.filter(coach => {
            // Filter based on multiple fields
            const fullName = `${coach.firstName} ${coach.lastName}`;
            const email = coach.email;
            const phoneNumber = coach.tel;

            // Check if coach.tel is a valid string before calling toLowerCase()
            const phoneNumberString = phoneNumber ? phoneNumber.toString() : '';

            // Return true if any of the fields match the searchTerm
            return (
                fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                phoneNumberString.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredCoaches(filteredCoaches);
    }

    return (
        <Fragment>
            {/* <PageTitle activeMenu="Table" motherMenu="Bootstrap" /> */}

            {userRole === "admin" && (
                <Button className="btn btn-primary font-w600 mb-2 mr-auto" onClick={handleShowModal}>+ Add New Coach</Button>
            )}

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Coach</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="lastName">Last Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="tel">Phone Number:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tel"
                                name="tel"
                                value={formData.tel}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="birthDate">Birthday:</label>
                            <input
                                type="date"
                                className="form-control"
                                id="birthDate"
                                name="birthDate"
                                value={formData.birthDate}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="gender">Gender:</label>
                            <select
                                className="form-control"
                                id="gender"
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Add Coach
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Coaches</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>Full Name</strong>
                                        </th>
                                        <th>
                                            <strong>Email</strong>
                                        </th>
                                        <th>
                                            <strong>Phone Number</strong>
                                        </th>
                                        <th>
                                            <strong>Actions</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredCoaches.map((coach) => (
                                        <tr key={coach.id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    {/* <img
                                                    src={avatar1}
                                                    className="rounded-lg mr-2"
                                                    width="24"
                                                    alt=""
                                                />{" "} */}
                                                    <span className="w-space-no">
                                                        {coach.firstName} {" "} {coach.lastName}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>{coach.email}</td>
                                            <td>{coach.tel}</td>
                                            {/* <td>
                                                01 August 2020
                                            </td> */}
                                            {/* <td>
                                            <div className="d-flex align-items-center">
                                                <i className="fa fa-circle text-success mr-1"></i>{" "}
                                                Successful
                                            </div>
                                        </td> */}
                                            <td>
                                                <div className="d-flex">
                                                    <Link
                                                        to={`/coach/${coach._id}`}
                                                        className="btn btn-primary shadow btn-xs sharp mr-1"
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                    {/* <Link
                                                        href="#"
                                                        className="btn btn-danger shadow btn-xs sharp"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Coaches;
