import React, { useState, useEffect } from "react";
import axiosInstance from '../../../services/AxiosInstance';

import swal from "sweetalert";


import { Modal, Button, Form } from "react-bootstrap";

const LevelTwo = (props) => {
    const [userId, setUserId] = useState('');
    const [levelTwos, setLevelTwos] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        date: '',
        KneePushUp: '',
        squat: '',
        flexibility: '',
        agilitySpeed: '',
        lowPlank: '',

    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
        const config = {
            headers: {
                authorization: "Bearer " + token
            }
        };

        // get the userId from the URL parameter
        const { id } = props.match.params;
        setUserId(id);

        // fetch all levelTwo data for the user
        const fetchLevelTwo = async () => {
            try {
                const response = await axiosInstance.get(`/levelTwo/${id}`, config);
                const formattedLevelTwos = response.data.map((levelTwo) => ({
                    ...levelTwo,
                    date: new Date(levelTwo.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                }));
                setLevelTwos(formattedLevelTwos);
            } catch (error) {
                console.log(error);
            }
        };
        fetchLevelTwo();
    }, []);

    const handleSubmit = async (event) => {
        const { id } = props.match.params;
        event.preventDefault(); // prevent the default form submit behavior

        // prepare the levelTwo data to be sent to the server
        const data = {
            date: formData.date,
            KneePushUp: formData.KneePushUp,
            squat: formData.squat,
            flexibility: formData.flexibility,
            agilitySpeed: formData.agilitySpeed,
            lowPlank: formData.lowPlank,
        };

        try {
            // send a post request to the server to save the new levelTwos
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token
                }
            };
            await axiosInstance.post(`/levelTwo/${id}`, data, config);

            // clear the form data and close the modal
            setFormData({
                date: '',
                KneePushUp: '',
                squat: '',
                flexibility: '',
                agilitySpeed: '',
                lowPlank: '',
            });
            setShowModal(false);

            // fetch the updated levelTwos and update the state
            const response = await axiosInstance.get(`/levelTwo/${userId}`, config);
            const formattedLevelTwos = response.data.map((levelTwo) => ({
                ...levelTwo,
                date: new Date(levelTwo.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })
            }));
            setLevelTwos(formattedLevelTwos);

            // show a success swal
            swal('Good job!', 'Successfully Added', 'success');

        } catch (error) {
            console.error(error);

            // show a fail swal
            swal({
                title: 'Oops...',
                text: 'Please fill all the fields and try again!',
                icon: 'error',
                buttons: {
                    confirm: "Try Again"
                }
            });
        }
    };



    return (
        <React.Fragment>

            <Button className="btn btn-primary font-w600 mb-2 mr-auto" onClick={handleShowModal}>+ Add New Results Level 2</Button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>New Results Level 1</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="form-group">

                            <label htmlFor="date">Date:</label>
                            <input
                                type="date"
                                name="date"
                                className="form-control"
                                value={formData.date}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">

                            <label htmlFor="height">Push Up Knee Down:</label>
                            <input
                                type="number"
                                name="KneePushUp"
                                className="form-control"
                                value={formData.KneePushUp}
                                onChange={handleInputChange}
                                required
                            />

                        </div>

                        <div className="form-group ">

                            <label htmlFor="weight">Squat/1Min:</label>
                            <input
                                type="number"
                                name="squat"
                                className="form-control"
                                value={formData.squat}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="fat">Flexibility:</label>
                            <input
                                type="number"
                                name="flexibility"
                                className="form-control"
                                value={formData.flexibility}
                                onChange={handleInputChange}
                                required
                            />

                        </div>
                        <div className="form-group">

                            <label htmlFor="muscle">Agility Speed:</label>
                            <input
                                type="number"
                                name="agilitySpeed"
                                className="form-control"
                                value={formData.agilitySpeed}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">

                            <label htmlFor="muscle">Low Plank Max Time (s):</label>
                            <input
                                type="number"
                                name="lowPlank"
                                className="form-control"
                                value={formData.lowPlank}
                                onChange={handleInputChange}
                                required
                            />
                        </div>


                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Add  Results
                    </Button>
                </Modal.Footer>
            </Modal>


            <div className="row">
                {levelTwos.map((levelTwo) => (
                    <div key={levelTwo.id} className="col-xl-4 col-xxl-6 col-lg-6">
                        <div className="card">
                            <div className="card-header bg-primary">
                                <div className="d-flex mr-3 align-items-center">
                                    {/* <i class="fa fa-calendar text-white"></i> */}
                                    <span className="p-3 mr-3 rounded-circle bg-white">
                                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 5.99998H5V3.99998C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 2.99998 6 2.99998C6.26522 2.99998 6.51957 3.10536 6.70711 3.29289C6.89464 3.48043 7 3.73478 7 3.99998V5.99998H17V3.99998C17 3.73478 17.1054 3.48043 17.2929 3.29289C17.4804 3.10536 17.7348 2.99998 18 2.99998C18.2652 2.99998 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 3.99998V5.99998H21V8.99998H3V5.99998ZM19 9.99998H5V19H19V9.99998ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11ZM7 15H9V17H7V15ZM11 15H13V17H11V15ZM15 15H17V17H15V15Z" fill="#e71f86" />
                                        </svg>
                                    </span>
                                    <h4 className="fs-20 text-white mb-0">{levelTwo.date}</h4>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table shadow-hover">

                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Push Up Knee Down
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{levelTwo.KneePushUp}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Squat/1Min
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{levelTwo.squat}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Flexibility
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{levelTwo.flexibility}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Agility Speed
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{levelTwo.agilitySpeed}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Low Plank Max Time
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{levelTwo.lowPlank}s</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default LevelTwo;
