import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import sessionBG from '../../images/login-bg-4.jpg';
import axiosInstance from '../../services/AxiosInstance';
import loginbg from '../../images/login-bg-4.jpg';
import logoLF from '../../images/logo-lf.png';

const CreatePassword = ({ match }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { passwordToken } = match.params;
    const [isTokenValid, setIsTokenValid] = useState(false);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            swal('Error', 'Passwords do not match', 'error');
            return;
        }

        try {
            // Send a request to the backend API to create the password
            const response = await axiosInstance.put(`/user/createPassword/${passwordToken}`, {
                password: password,
            });

            // Password created successfully
            swal({
                title: 'Success',
                text: 'Password created',
                icon: 'success',
                button: {
                    text: 'Login Now',
                    className: 'btn btn-primary',
                },
            }).then(() => {
                window.location.href = '/login'; // Redirect to the login page
            });
        } catch (error) {
            // Failed to create password
            swal('Error', 'Failed to create password', 'error');
        }
    };

    useEffect(() => {
        const checkPasswordTokenValidity = async () => {
            try {
                const response = await axiosInstance.get(`/user/checkPasswordTokenValidity/${passwordToken}`);
                setIsTokenValid(response.data.isValid);
            } catch (error) {
                setIsTokenValid(false);
                swal({
                    title: 'Oops!',
                    text: 'Invalid or expired password link.',
                    icon: 'error',
                    buttons: false,
                });
            }
        };

        checkPasswordTokenValidity();
    }, []);

    if (!isTokenValid) {
        return (
            <div
                style={{
                    backgroundImage: `url(${sessionBG})`,
                    backgroundSize: 'cover',
                    height: '100vh',
                    width: '100vw',
                }}
            ></div>
        );
    }

    return (
        <div
            className="login-main-page" style={{ backgroundImage: "url(" + loginbg + ")" }}
        >
            <div className="login-wrapper">

                <div className="login-aside-left" >
                    <Link to="/login" className="login-logo">
                        <img src={logoLF} alt="" />

                    </Link>
                    <div className="login-description">
                        <h2 className="main-title mb-2">Welcome to LadyFit App</h2>
                        <p className="">Thank you for choosing the LadyFit App as your fitness partner! Our platform is designed to provide you with a comprehensive and effective way to stay active and healthy. We believe that fitness is an essential part of a happy and fulfilling life, and we're dedicated to helping you achieve your goals.</p>
                        <ul className="social-icons mt-4">
                            <li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
                            <li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
                        </ul>
                        <div className="mt-3 bottom-privacy">
                            {/* <p>Copyright © Designed & Developed by <a href="https://dexignzone.com/" rel="noreferrer" target="_blank">DexignZone</a> 2021</p> */}
                        </div>
                    </div>
                </div>

                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                        <div className="p-5">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form-1">
                                            <div className="mb-4">
                                                <h3 className="dz-title mb-1">Create Your Password</h3>
                                                <p className="">By creating your password, you can have access to the LadyFit App and enjoy all its features.</p>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label className="mb-2 "><strong> Password</strong></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Type Your Password"
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label className="mb-2 "><strong>Confirm Password</strong></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Type Your Password"
                                                        value={confirmPassword}
                                                        onChange={handleConfirmPasswordChange}
                                                    />
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-primary btn-block">
                                                        Create Password
                                                    </button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreatePassword;
