import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © LadyFit 2023
          {/* Copyright © Designed &amp; Developed by{" "} */}
          {/* <a href="http://dexignzone.com/" target="_blank" rel="noreferrer">
            DexignZone
          </a>{" "} */}
          {/* 2023 */}
        </p>
      </div>
    </div>
  );
};

export default Footer;
