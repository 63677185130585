import React, { useState, useEffect } from "react";
import axiosInstance from '../../../services/AxiosInstance';


import { Link } from "react-router-dom";
import swal from "sweetalert";
import { CSVLink } from "react-csv";

import { Modal, Button, Form } from "react-bootstrap";


const Records = (props) => {
    const [userId, setUserId] = useState('');
    const [measurements, setMeasurements] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        date: '',
        height: '',
        weight: '',
        fat: '',
        muscle: '',
        bmi: '',
        arms: '',
        chest: '',
        waist: '',
        hips: '',
        legs: '',
        belly: '',
    });

    const [csvData, setCSVData] = useState([]); // CSV data state

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
        const config = {
            headers: {
                authorization: "Bearer " + token
            }
        };

        // get the userId from the URL parameter
        const { id } = props.match.params;
        setUserId(id);

        // fetch all measurement data for the user
        const fetchMeasurements = async () => {
            try {
                const response = await axiosInstance.get(`/measurement/measurements/${id}`, config);
                const formattedMeasurements = response.data.map((measurement) => ({
                    date: new Date(measurement.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    }),
                    height: measurement.height,
                    weight: measurement.weight,
                    fat: measurement.fat,
                    muscle: measurement.muscle,
                    bmi: measurement.bmi,
                    arms: measurement.arms,
                    chest: measurement.chest,
                    waist: measurement.waist,
                    hips: measurement.hips,
                    legs: measurement.legs,
                    belly: measurement.belly
                }));
                setMeasurements(formattedMeasurements);
                setCSVData(formattedMeasurements.map((measurement) => ({
                    date: measurement.date,
                    height: measurement.height,
                    weight: measurement.weight,
                    fat: measurement.fat,
                    muscle: measurement.muscle,
                    bmi: measurement.bmi,
                    arms: measurement.arms,
                    chest: measurement.chest,
                    waist: measurement.waist,
                    hips: measurement.hips,
                    legs: measurement.legs,
                    belly: measurement.belly
                })));
            } catch (error) {
                console.log(error);
            }
        };
        fetchMeasurements();
    }, []);


    const handleSubmit = async (event) => {
        const { id } = props.match.params;
        event.preventDefault(); // prevent the default form submit behavior

        // prepare the measurement data to be sent to the server
        const data = {
            date: formData.date,
            height: formData.height,
            weight: formData.weight,
            fat: formData.fat,
            muscle: formData.muscle,
            bmi: formData.bmi,
            arms: formData.arms,
            chest: formData.chest,
            waist: formData.waist,
            hips: formData.hips,
            legs: formData.legs,
            belly: formData.belly,
        };

        try {
            // send a post request to the server to save the new measurements
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token
                }
            };
            await axiosInstance.post(`/measurement/${id}`, data, config);

            // clear the form data and close the modal
            setFormData({
                date: '',
                height: '',
                weight: '',
                fat: '',
                muscle: '',
                bmi: '',
                arms: '',
                chest: '',
                waist: '',
                hips: '',
                legs: '',
                belly: '',
            });
            setShowModal(false);

            // fetch the updated measurements and update the state
            const response = await axiosInstance.get(`/measurement/measurements/${userId}`, config);
            const formattedMeasurements = response.data.map((measurement) => ({
                date: new Date(measurement.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }),
                height: measurement.height,
                weight: measurement.weight,
                fat: measurement.fat,
                muscle: measurement.muscle,
                bmi: measurement.bmi,
                arms: measurement.arms,
                chest: measurement.chest,
                waist: measurement.waist,
                hips: measurement.hips,
                legs: measurement.legs,
                belly: measurement.belly
            }));
            setMeasurements(formattedMeasurements);
            setCSVData(formattedMeasurements.map((measurement) => ({
                date: measurement.date,
                height: measurement.height,
                weight: measurement.weight,
                fat: measurement.fat,
                muscle: measurement.muscle,
                bmi: measurement.bmi,
                arms: measurement.arms,
                chest: measurement.chest,
                waist: measurement.waist,
                hips: measurement.hips,
                legs: measurement.legs,
                belly: measurement.belly
            })));
            // show a success swal
            swal('Good job!', 'Successfully Added', 'success');

        } catch (error) {
            console.error(error);

            // show a fail swal
            swal({
                title: 'Oops...',
                text: 'Please fill all the fields and try again!',
                icon: 'error',
                buttons: {
                    confirm: "Try Again"
                }
            });
        }
    };



    return (
        <React.Fragment>

            <Button className="btn btn-primary font-w600 mb-2 mr-auto" onClick={handleShowModal}>+ Add New Measurements</Button>
            <CSVLink data={csvData} filename={'measurements.csv'} className="btn btn-secondary font-w600 mb-2 mr-auto ml-2">
                Export CSV
            </CSVLink>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Measurements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="date">Date:</label>
                                <input
                                    type="date"
                                    name="date"
                                    className="form-control"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="height">Height:</label>
                                <input
                                    type="number"
                                    name="height"
                                    className="form-control"
                                    value={formData.height}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="weight">Weight:</label>
                                <input
                                    type="number"
                                    name="weight"
                                    className="form-control"
                                    value={formData.weight}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="fat">Fat:</label>
                                <input
                                    type="number"
                                    name="fat"
                                    className="form-control"
                                    value={formData.fat}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="muscle">Muscle:</label>
                                <input
                                    type="number"
                                    name="muscle"
                                    className="form-control"
                                    value={formData.muscle}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="bmi">BMI:</label>
                                <input
                                    type="number"
                                    name="bmi"
                                    className="form-control"
                                    value={formData.bmi}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="arms">Arms:</label>
                                <input
                                    type="number"
                                    name="arms"
                                    className="form-control"
                                    value={formData.arms}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="chest">Chest:</label>
                                <input
                                    type="number"
                                    name="chest"
                                    className="form-control"
                                    value={formData.chest}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="waist">Waist:</label>
                                <input
                                    type="number"
                                    name="waist"
                                    className="form-control"
                                    value={formData.waist}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="hips">Hips:</label>
                                <input
                                    type="number"
                                    name="hips"
                                    className="form-control"
                                    value={formData.hips}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label htmlFor="legs">Legs:</label>
                                <input
                                    type="number"
                                    name="legs"
                                    className="form-control"
                                    value={formData.legs}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-6">

                                <label htmlFor="chest">Belly:</label>
                                <input
                                    type="number"
                                    name="belly"
                                    className="form-control"
                                    value={formData.belly}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Add Measurements
                    </Button>
                </Modal.Footer>
            </Modal>



            <div className="row">
                {measurements.map((measurement) => (
                    <div key={measurement.id} className="col-xl-4 col-xxl-6 col-lg-6">
                        <div className="card">
                            <div className="card-header bg-primary">
                                <div className="d-flex mr-3 align-items-center">
                                    {/* <i class="fa fa-calendar text-white"></i> */}
                                    <span className="p-3 mr-3 rounded-circle bg-white">
                                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 5.99998H5V3.99998C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 2.99998 6 2.99998C6.26522 2.99998 6.51957 3.10536 6.70711 3.29289C6.89464 3.48043 7 3.73478 7 3.99998V5.99998H17V3.99998C17 3.73478 17.1054 3.48043 17.2929 3.29289C17.4804 3.10536 17.7348 2.99998 18 2.99998C18.2652 2.99998 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 3.99998V5.99998H21V8.99998H3V5.99998ZM19 9.99998H5V19H19V9.99998ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11ZM7 15H9V17H7V15ZM11 15H13V17H11V15ZM15 15H17V17H15V15Z" fill="#e71f86" />
                                        </svg>
                                    </span>
                                    <h4 className="fs-20 text-white mb-0">{measurement.date}</h4>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    <table className="table shadow-hover">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className="font-w600 text-black fs-16">
                                                        Measurement
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className="font-w600 text-black fs-16">
                                                        Value
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Height
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.height}cm</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Weight
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.weight}kg</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Body Fat
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.fat}%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Muscle Mass
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.muscle}%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        BMI
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.bmi}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Arms
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.arms}cm</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Chest
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.chest}cm</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Waist
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.waist}cm</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Hips
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.hips}cm</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Legs
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.legs}cm</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-black mb-1 font-w600">
                                                        Belly
                                                    </p>
                                                </td>
                                                <td>
                                                    <span className="fs-14">{measurement.belly}cm</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment >
    );
};

export default Records;
