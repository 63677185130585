import React, { useState, useEffect } from "react";
import axiosInstance from '../../../services/AxiosInstance';

import swal from "sweetalert";


import { Modal, Button, Form } from "react-bootstrap";


const SessionsHistory = (props) => {
    const [userId, setUserId] = useState('');
    const [session, setSession] = useState([]);

    const [showModal, setShowModal] = useState(false);


    const [formData, setFormData] = useState({
        startTime: '',
        endTime: '',
        exercises: [],
        status: 'Pending', // Default status
    });

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleInputChange = (event, exerciseIndex, setIndex) => {
        const { name, value } = event.target;

        if (setIndex !== undefined) {
            const updatedExercises = [...formData.exercises];
            updatedExercises[exerciseIndex].sets[setIndex] = {
                ...updatedExercises[exerciseIndex].sets[setIndex],
                [name]: value,
            };
            setFormData((prevData) => ({ ...prevData, exercises: updatedExercises }));
        } else if (exerciseIndex !== undefined) {
            const updatedExercises = [...formData.exercises];
            updatedExercises[exerciseIndex] = {
                ...updatedExercises[exerciseIndex],
                name: value,
            };
            setFormData((prevData) => ({ ...prevData, exercises: updatedExercises }));
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleAddSet = (exerciseIndex) => {
        const updatedExercises = [...formData.exercises];
        const setNumber = updatedExercises[exerciseIndex].sets.length + 1;
        const newSet = {
            title: `Set ${setNumber}`,
            weight: '',
            reps: '',
            restTime: '',
            duration: '',
        };
        updatedExercises[exerciseIndex].sets.push(newSet);
        setFormData((prevData) => ({ ...prevData, exercises: updatedExercises }));
    };

    const handleAddExercise = () => {
        const updatedExercises = [...formData.exercises];
        updatedExercises.push({ name: '', sets: [] });
        setFormData((prevData) => ({ ...prevData, exercises: updatedExercises }));
    };

    const handleRemoveExercise = (exerciseIndex) => {
        const updatedExercises = [...formData.exercises];
        updatedExercises.splice(exerciseIndex, 1);
        setFormData((prevData) => ({ ...prevData, exercises: updatedExercises }));
    };

    const handleRemoveSet = (exerciseIndex, setIndex) => {
        const updatedExercises = [...formData.exercises];
        updatedExercises[exerciseIndex].sets.splice(setIndex, 1);

        // Update set titles based on the updated set indices
        updatedExercises[exerciseIndex].sets.forEach((set, index) => {
            set.title = `Set ${index + 1}`;
        });

        setFormData((prevData) => ({ ...prevData, exercises: updatedExercises }));
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     // Perform form submission or data processing here
    //     console.log(formData); // Replace with your logic
    // };







    const handleSubmit = async (event) => {
        const { id } = props.match.params;
        event.preventDefault();

        const data = {
            startTime: formData.startTime,
            endTime: formData.endTime,
            exercises: formData.exercises,
            status: formData.status,
        };

        try {
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token,
                },
            };
            await axiosInstance.post(`/session/${id}`, data, config);

            setFormData({
                startTime: "",
                endTime: "",
                exercises: [{ name: "", sets: [] }],
                status: "Pending", // Reset the status to Pending
            });
            setShowModal(false);

            const response = await axiosInstance.get(`/session/${id}`, config);
            const formattedSession = response.data.map((session) => ({
                ...session,
                date: new Date(session.startTime).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }),
            }));
            setSession(formattedSession);

            swal("Good job!", "Successfully Added", "success");
        } catch (error) {
            console.error(error);

            swal({
                title: "Oops...",
                text: "Please fill all the fields and try again!",
                icon: "error",
                buttons: {
                    confirm: "Try Again",
                },
            });
        }
    };

    const sendConfirmationEmail = async (sessionId) => {
        console.log('Session ID:', sessionId);
        try {
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token,
                },
            };

            // Send a request to your server to send the confirmation email
            await axiosInstance.put(`/session/${sessionId}/sendConfirmationEmail`, null, config);

            // Display a success message or perform any other desired action
            swal("Email Sent", "Confirmation email has been sent.", "success");
        } catch (error) {
            console.error(error);
            swal("Error", "Failed to send confirmation email.", "error");
        }
    };


    const calculateDuration = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const durationInMilliseconds = end - start;
        const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
        return durationInMinutes;
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
        const config = {
            headers: {
                authorization: "Bearer " + token
            }
        };

        // get the userId from the URL parameter
        const { id } = props.match.params;
        setUserId(id);

        // fetch all session data for the user
        const fetchSession = async () => {
            try {
                const response = await axiosInstance.get(`/session/${id}`, config);
                const formattedSession = response.data.map((session) => ({
                    ...session,
                    date: new Date(session.startTime).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                }));
                console.log(formattedSession);
                setSession(formattedSession);
            } catch (error) {
                console.log(error);
            }
        };
        fetchSession();
    }, []);
    return (
        <React.Fragment>

            <Button className="btn btn-primary font-w600 mb-2 mr-auto" onClick={handleShowModal}>+ Add New Session</Button>
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="status">
                            <Form.Label>Status</Form.Label>
                            <div>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Pending"
                                    name="status"
                                    value="Pending"
                                    checked={formData.status === "Pending"}
                                    onChange={handleInputChange}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Old"
                                    name="status"
                                    value="Old"
                                    checked={formData.status === "Old"}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="startTime">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="startTime"
                                value={formData.startTime}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="endTime">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="endTime"
                                value={formData.endTime}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        {formData.exercises.map((exercise, exerciseIndex) => (
                            <div key={exerciseIndex}>
                                <Form.Group controlId={`exerciseName_${exerciseIndex}`}>
                                    <h3>Exercise Name</h3>
                                    <Form.Control
                                        type="text"
                                        name={`exerciseName`}
                                        value={exercise.name}
                                        onChange={(event) => handleInputChange(event, exerciseIndex)}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="danger" onClick={() => handleRemoveExercise(exerciseIndex)}>
                                    <i className="fa fa-trash" /> Remove Exercise
                                </Button>
                                {exercise.sets.map((set, setIndex) => (
                                    <div key={setIndex}>

                                        <Form.Group className="mt-2">

                                            <h3>{set.title}</h3>
                                            <Button variant="danger" onClick={() => handleRemoveSet(exerciseIndex, setIndex)}>
                                                <i className="fa fa-trash" /> Remove Set
                                            </Button>
                                        </Form.Group>

                                        <div className="form-group row">
                                            <div className="col-6">
                                                <Form.Group controlId={`weight_${exerciseIndex}_${setIndex}`}>
                                                    <Form.Label>Weight</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={`weight`}
                                                        value={set.weight}
                                                        onChange={(event) => handleInputChange(event, exerciseIndex, setIndex)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-6">
                                                <Form.Group controlId={`reps_${exerciseIndex}_${setIndex}`}>
                                                    <Form.Label>Reps</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={`reps`}
                                                        value={set.reps}
                                                        onChange={(event) => handleInputChange(event, exerciseIndex, setIndex)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-6">
                                                <Form.Group controlId={`restTime_${exerciseIndex}_${setIndex}`}>
                                                    <Form.Label>Rest Time</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={`restTime`}
                                                        value={set.restTime}
                                                        onChange={(event) => handleInputChange(event, exerciseIndex, setIndex)}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-6">
                                                <Form.Group controlId={`duration_${exerciseIndex}_${setIndex}`}>
                                                    <Form.Label>Duration</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name={`duration`}
                                                        value={set.duration}
                                                        onChange={(event) => handleInputChange(event, exerciseIndex, setIndex)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <Button variant="primary" onClick={() => handleAddSet(exerciseIndex)}>Add Set</Button>
                            </div>
                        ))}

                        <Button variant="primary" onClick={handleAddExercise}>Add Exercise</Button>
                    </Form>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Add Session
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="row">
                {session.map((session) => (
                    <div key={session.id} className="col-xl-6 col-xxl-6 col-lg-6">
                        <div className="card">
                            <div className="card-header bg-primary">
                                <div className="d-flex mr-3 align-items-center">
                                    {/* <i class="fa fa-calendar text-white"></i> */}
                                    <span className="p-3 mr-3 rounded-circle bg-white">
                                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 5.99998H5V3.99998C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 2.99998 6 2.99998C6.26522 2.99998 6.51957 3.10536 6.70711 3.29289C6.89464 3.48043 7 3.73478 7 3.99998V5.99998H17V3.99998C17 3.73478 17.1054 3.48043 17.2929 3.29289C17.4804 3.10536 17.7348 2.99998 18 2.99998C18.2652 2.99998 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 3.99998V5.99998H21V8.99998H3V5.99998ZM19 9.99998H5V19H19V9.99998ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11ZM7 15H9V17H7V15ZM11 15H13V17H11V15ZM15 15H17V17H15V15Z" fill="#e71f86" />
                                        </svg>
                                    </span>
                                    <h4 className="fs-20 text-white mb-0">{session.date}</h4>

                                </div>
                                <p>
                                    <h4 className="fs-20 text-white mb-0">
                                        Duration: {calculateDuration(session.startTime, session.endTime)} minutes
                                    </h4>

                                    <h4 className="fs-20 text-white mb-0">
                                        Status: {session.status}
                                    </h4>
                                </p>
                            </div>
                            <div className="card-body p-0">
                                <div className="table-responsive">
                                    {session.exercises.map((exercise) => (
                                        <table className="table shadow-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span className="font-w600 text-black fs-16">
                                                            {exercise.name}
                                                        </span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="font-w600 text-black fs-16">
                                                            Set Number
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="font-w600 text-black fs-16">
                                                            Reps
                                                        </span>
                                                    </th>

                                                    <th>
                                                        <span className="font-w600 text-black fs-16">
                                                            Weight (kg)
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="font-w600 text-black fs-16">
                                                            Duration (s)
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="font-w600 text-black fs-16">
                                                            Rest Time (s)
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {exercise.sets.map((set, index) => (
                                                    <tr key={set.id}>
                                                        <td>
                                                            <p className="text-black mb-1 font-w600">
                                                                Set {index + 1}                                                </p>
                                                        </td>
                                                        <td>
                                                            <span className="fs-14">{set.reps}</span>
                                                        </td>
                                                        <td>
                                                            <span className="fs-14">{set.weight}</span>
                                                        </td>
                                                        <td>
                                                            <span className="fs-14">{set.duration}</span>
                                                        </td>
                                                        <td>
                                                            <span className="fs-14">{set.restTime}</span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ))}
                                </div>
                            </div>
                            <div className="card-footer d-flex bg-primary">
                                <div className="d-flex mr-3 align-items-center">
                                    <h4 className="fs-20 text-white mb-0">supervised by {session.coach.firstName} {" "} {session.coach.lastName}</h4>
                                </div>
                                {session.status === 'Pending' && (
                                    <Button className="btn bg-white text-primary font-w600 mt-sm-0 mt-3 mb-0 ml-auto" onClick={() => sendConfirmationEmail(session._id)}>
                                        Send Confirmation Email
                                    </Button>
                                )}

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment >
    );
};

export default SessionsHistory;
