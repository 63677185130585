import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import sessionBG from "../../images/login-bg-4.jpg";
import axios from 'axios';
import axiosInstance from '../../services/AxiosInstance';


const Session = ({ match }) => {
  const [success, setSuccess] = useState(false);
  const [alreadyCompleted, setAlreadyCompleted] = useState(false);

  useEffect(() => {
    const confirmSession = async () => {
      try {
        const response = await axiosInstance.put(`/session/${match.params.sessionId}/confirm/${match.params.confirmationToken}`);
        if (response.data.message === 'Session already marked as completed') {
          setAlreadyCompleted(true);
        } else {
          setSuccess(true);
        }
      } catch (error) {
        setSuccess(false);
      }
    }
    confirmSession();
  }, [match.params.sessionId, match.params.confirmationToken]);

  useEffect(() => {
    if (alreadyCompleted) {
      swal({
        title: 'Warning!',
        text: 'This session has already been marked as completed.',
        icon: 'warning',
        buttons: false,
      });
    } else if (success) {
      swal({
        title: 'Thank You!',
        text: 'Your session is marked as completed.',
        icon: 'success',
        buttons: false,
      });
    } else {
      swal({
        title: 'Oops!',
        text: 'Something went wrong. Please try again later.',
        icon: 'error',
        buttons: false,
      });
    }
  }, [success, alreadyCompleted]);

  return (
    <div
      style={{
        backgroundImage: "url(" + sessionBG + ")",
        backgroundSize: 'cover',
        height: '100vh',
        width: '100vw',
      }}
    ></div>
  );
};

export default Session;
