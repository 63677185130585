import axios from 'axios';
import { store } from '../store/store';



const axiosInstance = axios.create({
    //baseURL: `https://lf-backend-iwmw.onrender.com/api`,
    baseURL: process.env.REACT_APP_API_URL,
});

// axiosInstance.interceptors.request.use((config) => {
//     const state = store.getState();
//     const token = state.auth.auth.idToken;
//     config.params = config.params || {};
//     config.params['auth'] = token;
//     console.log(config);
//     return config;
// });

export default axiosInstance;
