import React, { Fragment, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Form,
} from "react-bootstrap";

import swal from "sweetalert";
import axiosInstance from '../../../services/AxiosInstance';


/// imge
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import { Link } from "react-router-dom";

const Members = () => {
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );

    const [members, setMembers] = React.useState([]);
    const [filteredMembers, setFilteredMembers] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");


    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        tel: "",
        birthDate: "",
        gender: "",
        occupation: "",
        fitnessGoal: "",
        otherActivity: "",
        weightTraining: false,
        trainingFrequency: "",
        commitment: "",
        active: false,
    });

    const handleInputChange = (event) => {
        const { name, value, type } = event.target;

        // Check if the input is a radio button
        if (type === 'radio') {
            const parsedValue = value === 'true'; // Convert the string value to a boolean
            setFormData({
                ...formData,
                [name]: parsedValue,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // prevent the default form submit behavior

        // prepare the member data to be sent to the server
        const data = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            tel: formData.tel,
            birthDate: formData.birthDate,
            gender: formData.gender,
            fitnessGoal: formData.fitnessGoal,
            otherActivity: formData.otherActivity,
            occupation: formData.occupation,
            weightTraining: formData.weightTraining,
            trainingFrequency: formData.trainingFrequency,
            commitment: formData.commitment,
            active: formData.active,

        };

        try {
            // send a post request to the server to save the new member
            const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
            const config = {
                headers: {
                    authorization: "Bearer " + token,
                },
            };
            await axiosInstance.post(`/user/createMember`, data, config);

            // clear the form data and close the modal
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                tel: "",
                birthDate: "",
                gender: "",
                occupation: "",
                fitnessGoal: "",
                otherActivity: "",
                weightTraining: false,
                trainingFrequency: "",
                commitment: "",
                active: false
            });
            setShowModal(false);

            // fetch the updated members and update the state
            const response = await axiosInstance.get(`/user/clients`, config);
            setMembers(response.data);
            setFilteredMembers(response.data);

            // show a success swal
            swal('Good job!', 'Successfully Added', 'success');
        } catch (error) {
            console.error(error);

            // show a fail swal
            swal({
                title: 'Oops...',
                text: 'Please fill all the fields and try again!',
                icon: 'error',
                buttons: {
                    confirm: "Try Again"
                }
            });
        }
    };


    React.useEffect(() => {
        const token = JSON.parse(localStorage.getItem("userDetails")).idToken
        const config = {
            headers: {
                authorization: "Bearer " + token
            }
        }

        const fetchUsers = async () => {
            try {
                const response = await axiosInstance.get("/user/clients", config)
                setMembers(response.data)
                setFilteredMembers(response.data)
                //console.log("data", response.data);
            } catch (error) {
                // console.log(error);
            }
        }
        fetchUsers()
    }, [])


    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        const filteredMembers = members.filter(member => {
            // Filter based on multiple fields
            const fullName = `${member.firstName} ${member.lastName}`;
            const email = member.email;
            const phoneNumber = member.tel;

            // Check if member.tel is a valid string before calling toLowerCase()
            const phoneNumberString = phoneNumber ? phoneNumber.toString() : '';

            // Return true if any of the fields match the searchTerm
            return (
                fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                phoneNumberString.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredMembers(filteredMembers);
    }

    return (
        <Fragment>
            {/* <PageTitle activeMenu="Table" motherMenu="Bootstrap" /> */}

            <Button className="btn btn-primary font-w600 mb-2 mr-auto" onClick={handleShowModal}>+ Add New Member</Button>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add New Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row form-group">
                            <div className="col-6">
                                <label htmlFor="firstName">First Name:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="col-6">
                                <label htmlFor="lastName">Last Name:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>


                        <div className="row form-group">
                            <div className="col-6">
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="col-6">
                                <label htmlFor="tel">Phone Number:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="tel"
                                    name="tel"
                                    value={formData.tel}
                                    min="1"
                                    max="10"
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-6">
                                <label htmlFor="birthDate">Birthday:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="birthDate"
                                    name="birthDate"
                                    value={formData.birthDate}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="col-6">
                                <label htmlFor="gender">Gender:</label>
                                <select
                                    className="form-control"
                                    id="gender"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                        </div>


                        <div className="row form-group">
                            <div className="col-6">
                                <label htmlFor="occupation">Occupation:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="occupation"
                                    name="occupation"
                                    value={formData.occupation}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="col-6">
                                <label htmlFor="trainingFrequency">Training Frequency/week:</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="trainingFrequency"
                                    name="trainingFrequency"
                                    value={formData.trainingFrequency}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row form-group">
                            <div className="col-6">
                                <label htmlFor="fitnessGoal">Primary Fitness Goal:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fitnessGoal"
                                    name="fitnessGoal"
                                    value={formData.fitnessGoal}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="commitment">Commitment to the goal (1-10):</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="commitment"
                                    name="commitment"
                                    value={formData.commitment}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>


                        <div className="form-group">
                            <label htmlFor="otherActivity">Other Activity:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="otherActivity"
                                name="otherActivity"
                                value={formData.otherActivity}
                                onChange={handleInputChange}
                                required
                            />
                        </div>


                        <div className="row form-group">
                            <div className="col-6">
                                <label>Currently participating in weight training:</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="weightTraining"
                                            id="weightTrainingYes"
                                            value={true}
                                            checked={formData.weightTraining === true}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="weightTrainingYes">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="weightTraining"
                                            id="weightTrainingNo"
                                            value={false}
                                            checked={formData.weightTraining === false}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="weightTrainingNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">

                                <label>Worked out before:</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="active"
                                            id="activeYes"
                                            value={true}
                                            checked={formData.active === true}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="activeYes">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="active"
                                            id="activeNo"
                                            value={false}
                                            checked={formData.active === false}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="activeNo">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>
                        Add Member
                    </Button>
                </Modal.Footer>
            </Modal>


            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Members</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>Full Name</strong>
                                        </th>
                                        <th>
                                            <strong>Email</strong>
                                        </th>
                                        <th>
                                            <strong>Phone Number</strong>
                                        </th>
                                        <th>
                                            <strong>Actions</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredMembers.map((member) => (
                                        <tr key={member.id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    {/* <img
                                                    src={avatar1}
                                                    className="rounded-lg mr-2"
                                                    width="24"
                                                    alt=""
                                                />{" "} */}
                                                    <span className="w-space-no">
                                                        {member.firstName} {" "} {member.lastName}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>{member.email}</td>
                                            <td>{member.tel}</td>
                                            {/* <td>
                                                01 August 2020
                                            </td> */}
                                            {/* <td>
                                            <div className="d-flex align-items-center">
                                                <i className="fa fa-circle text-success mr-1"></i>{" "}
                                                Successful
                                            </div>
                                        </td> */}
                                            <td>
                                                <div className="d-flex">
                                                    <Link
                                                        to={`/member/${member._id}`}
                                                        className="btn btn-primary shadow btn-xs sharp mr-1"
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </Link>
                                                    {/* <Link
                                                        href="#"
                                                        className="btn btn-danger shadow btn-xs sharp"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Members;
