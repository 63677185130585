import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../../services/AxiosInstance";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import { Dropdown, Tab, Nav } from "react-bootstrap";

// import Ext from "../../layouts/Ext";

const ApexLine4 = loadable(() =>
   pMinDelay(import("../charts/apexcharts/Line4"), 500)
);
const ApexNagetivePosative = loadable(() =>
   pMinDelay(import("../charts/apexcharts/NagetivePositive2"), 500)
);
const ApexRedialBar = loadable(() =>
   pMinDelay(import("../charts/apexcharts/RadialBar"), 500)
);






const Home = () => {

   const [clientCount, setClientCount] = useState(0);
   const [coachCount, setCoachCount] = useState(0);

   useEffect(() => {
      const token = JSON.parse(localStorage.getItem("userDetails")).idToken;
      const config = {
         headers: {
            Authorization: "Bearer " + token
         }
      };

      const fetchCounts = async () => {
         try {
            const [clientCountResponse, coachCountResponse] = await Promise.all([
               axiosInstance.get('/user/countClients', config),
               axiosInstance.get('/user/countCoaches', config)
            ]);

            const clientCount = clientCountResponse.data.count;
            const coachCount = coachCountResponse.data.count;

            setClientCount(clientCount);
            setCoachCount(coachCount);
         } catch (error) {
            console.log(error);
         }
      };

      fetchCounts();
   }, []);

   function SampleNextArrow(props) {
      const { onClick } = props;
      return (
         <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
            <i className="fa fa-caret-right" />
         </div>
      );
   }

   function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
         <div
            className="owl-prev disabled"
            onClick={onClick}
            style={{ zIndex: 99 }}
         >
            <i className="fa fa-caret-left" />
         </div>
      );
   }

   const settings = {
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
         {
            breakpoint: 1599,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 990,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };
   return (
      <>
         <div className="row">


            <div className='col-xl-6 col-xxl-6 col-lg-6 col-sm-6'>
               <a
                  href="/coaches"
               >
                  <div className='widget-stat card bg-primary'>
                     <div className='card-body  p-4'>
                        <div className='media'>
                           <span className='mr-3'>
                              <i className='la la-users'></i>
                           </span>
                           <div className='media-body text-white'>
                              <p className='mb-1'>Total Coaches</p>
                              <h3 className='text-white'>{coachCount}</h3>
                              {/* <div className='progress mb-2 bg-secondary'>
                                 <div
                                    className='progress-bar progress-animated bg-light'
                                    style={{ width: '80%' }}
                                 ></div>
                              </div>
                              <small>80% Increase in 20 Days</small> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </a>
            </div>

            <div className='col-xl-6 col-xxl-6 col-lg-6 col-sm-6'>
               <a
                  href="/members"
               >
                  <div className='widget-stat card bg-info'>
                     <div className='card-body  p-4'>
                        <div className='media'>
                           <span className='mr-3'>
                              <i className='la la-users'></i>
                           </span>
                           <div className='media-body text-white'>
                              <p className='mb-1'>Total Members</p>
                              <h3 className='text-white'>{clientCount}</h3>
                              {/* <div className='progress mb-2 bg-secondary'>
                                 <div
                                    className='progress-bar progress-animated bg-light'
                                    style={{ width: '80%' }}
                                 ></div>
                              </div>
                              <small>80% Increase in 20 Days</small> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </a>
            </div>


         </div>
      </>
   );
};

export default Home;
